import React, { useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import SensorStatusCard from '../../components/sensorstatuscard/SensorStatusCard';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 340,
  },
}));

export default function Sensors({ onForbiddenAccess }) {
  const [sensorCards, setSensorCards] = useState([]);
  const [mInitialized, setInitialized] = useState(false);
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    async function overviewPreload() {
      let receivedKeys = {};
      const displayedCards = [];
      try {
        const apikeyRequest = await axios.get('/data/overview/apikeys');
        if (apikeyRequest.status === 200) {
          receivedKeys = apikeyRequest.data;
        }
        const viewRequest = await axios.get('/data/overview/views');
        setInitialized(true);
        if (viewRequest.status === 200) {
          const cardData = viewRequest.data;
          cardData.view.forEach((card, index) => {
            const cardView = card.card;
            const detailView = card.detail;

            cardView.data.forEach((dataPoint, idx) => {
              const cardAPIKEY = receivedKeys[dataPoint.apikey];
              cardView.data[idx].apikey = cardAPIKEY;
            });
            detailView.data.forEach((dataPoint, idx) => {
              const detailAPIKEY = receivedKeys[dataPoint.apikey];
              detailView.data[idx].apikey = detailAPIKEY;
            });
            const cardGrid = (
              <Grid item xs={12} md={4} lg={4} key={index}>
                <SensorStatusCard
                  className={fixedHeightPaper}
                  sensorInfo={cardView}
                  title={card.name}
                  details={detailView}
                />
              </Grid>
            );
            displayedCards.push(cardGrid);
          });
          setSensorCards(displayedCards);
        }
      } catch (e) {
        onForbiddenAccess();
      }
    }
    overviewPreload();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {mInitialized
        ? (
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {sensorCards}
            </Grid>
          </Container>
          )
        : (
          <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          )}
      </main>
    </div>
  );
}

Sensors.propTypes = {
  onForbiddenAccess: PropTypes.instanceOf(Function).isRequired,
};

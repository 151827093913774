import React, { useState, useEffect } from 'react';
// import TableCell from '@material-ui/core/TableCell';
// import Typography from '@material-ui/core/Typography';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Container,
    Grid,
    makeStyles,
    CssBaseline,
    Paper,
    Button,
    CircularProgress,
} from '@material-ui/core';
// import classNames from 'clsx';
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
    AppointmentTooltip,
    EditRecurrenceMenu,
    Resources,
    DragDropProvider,
  } from '@devexpress/dx-react-scheduler-material-ui';
import clsx from 'clsx';
import axios from 'axios';
import CalendarDialog, { ScheduleDeleteDialog } from '../../components/calendardialog/CalendarDialog';
// look at https://devexpress.github.io/devextreme-reactive/react/scheduler/demos/featured/overview/

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '50%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

export default function Calendar() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);

    const [mAddScheduleOpen, setAddScheduleOpen] = useState(false);
    const [mRemoveScheduleDialogOpen, setRemoveScheduleDialogOpen] = useState(false);
    const [mConfigCounter, setConfigCounter] = useState(0);
    const [mCalendarData, setCalendarData] = useState([]);
    const [mRessources, setRessources] = useState([]);
    const [mScheduleIdentifier, setScheduleIdentifier] = useState('');
    const [mScheduleObject, setScheduleObject] = useState({});
    const [mInitialized, setInitialized] = useState(false);

    const mColors = [
                    '#1abc9c',
                    '#2ecc71',
                    '#3498db',
                    '#9b59b6',
                    '#34495e',
                    '#f1c40f',
                    '#e67e22',
                    '#e74c3c',
                    '#ecf0f1',
                    '#95a5a6',
                    '#16a085',
                    '#27ae60',
                    '#2980b9',
                    '#8e44ad',
                    '#2c3e50',
                    '#f39c12',
                    '#d35400',
                    '#c0392b',
                    '#bdc3c7',
                    '#7f8c8d',
                  ];

    const openAddSchedule = () => {
      setScheduleObject({});
      setAddScheduleOpen(true);
    };

    const closeAddSchedule = () => {
      setAddScheduleOpen(false);
    };

    const openRemoveSchedule = () => {
      setRemoveScheduleDialogOpen(true);
    };

    const closeRemoveSchedule = () => {
      setRemoveScheduleDialogOpen(false);
    };

    const increaseConfigCounter = () => {
      setConfigCounter(mConfigCounter + 1);
    };

    const editSchedule = ({ added, changed, deleted }) => {
      if (added || changed) {
        return;
      }
      if (deleted) {
        setScheduleIdentifier(deleted);
        openRemoveSchedule();
      }
    };

    const openEditSchedule = () => {
      setAddScheduleOpen(true);
    };

    const handleScheduleObjectChange = (schedule) => {
      setScheduleObject(schedule.data);
    };

    useEffect(() => {
      const getSchedule = async () => {
        const usersObject = {};
        const users = await axios.get('/data/todo/users');
        if (users.status === 200) {
          if (users.data.users) {
            const userRessources = [];
            users.data.users.forEach((user, idx) => {
              usersObject[user.id] = { index: idx, name: user.name };
              userRessources.push({ text: user.name, id: idx, color: mColors[idx] });
            });
            const ressourceArray = [{ fieldName: 'ownerId', title: 'Personen', instances: userRessources }];
            setRessources(ressourceArray);
            const request = await axios.get('/data/calendar/entries');
            setInitialized(true);
            if (request.status === 200 && request.data.entries) {
              const entryList = [];
              request.data.entries.forEach((el) => {
                const scheduleEntry = {
                  id: el.id,
                  startDate: el.startDate,
                  endDate: el.endDate,
                  title: el.title,
                  ownerId: usersObject[el.ownerId].index,
                  allDay: true,
                };
                entryList.push(scheduleEntry);
              });
              setCalendarData(entryList);
            }
          }
        }
      };
      getSchedule();
    }, [mConfigCounter]);

    const commandButtonComponent = (type) => {
      if (type.id === 'open') {
        return <AppointmentTooltip.CommandButton id="open" onExecute={openEditSchedule} />;
      }
      if (type.id === 'delete') {
        return <AppointmentTooltip.CommandButton id="delete" onExecute={type.onExecute} />;
      }
        return <AppointmentTooltip.CommandButton id="close" onExecute={type.onExecute} />;
    };
    return (
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {mInitialized
          ? (
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <div className={classes.buttons}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={openAddSchedule}
                      >
                        Termin hinzufügen
                      </Button>
                      <CalendarDialog
                        open={mAddScheduleOpen}
                        onClose={closeAddSchedule}
                        scheduleAdded={increaseConfigCounter}
                        editableScheduleObject={mScheduleObject}
                      />
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <Scheduler
                      data={mCalendarData}
                    >
                      <EditingState
                        onCommitChanges={editSchedule}
                        preCommitChanges={openEditSchedule}
                      />
                      <ViewState />
                      <MonthView />
                      <Appointments />
                      <Resources
                        data={mRessources}
                      />
                      <Toolbar />
                      <DateNavigator />
                      <EditRecurrenceMenu />
                      <AppointmentTooltip
                        showOpenButton
                        showDeleteButton
                        showCloseButton
                        onAppointmentMetaChange={handleScheduleObjectChange}
                        commandButtonComponent={(id) => commandButtonComponent(id)}
                      />
                      <DragDropProvider />
                    </Scheduler>
                  </Paper>
                </Grid>
              </Grid>
              <ScheduleDeleteDialog
                open={mRemoveScheduleDialogOpen}
                onClose={closeRemoveSchedule}
                scheduleDeleted={increaseConfigCounter}
                scheduleDetails={mScheduleIdentifier}
              />
            </Container>
          )
          : (
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </div>
          )}
        </main>
      </div>
    );
}

import React, { useCallback, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';
import CryptoJS from 'crypto-js/core';
import JWT from 'jsonwebtoken';
import axios from 'axios';
import Navigation from './components/navigation/Navigation.tsx';

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Sensors from './pages/sensors/Sensors';
import Calendar from './pages/calendar/Calendar';
import ToDo from './pages/todo/ToDo';
import Account from './pages/account/Account';

import NotFound from './pages/notfound/NotFound';
import Copyright from './components/copyrightdisclaimer/CopyrightDisclaimer';
import PasswordRecovery from './pages/passwordrecovery/PasswordRecovery';
import Maintenance from './pages/maintenance/Maintenance';

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const getNonce = () => {
    const nonce = CryptoJS.lib.WordArray.random(4).toString(Hex).toUpperCase();
    return nonce;
  };

  useEffect(() => {
    const token = localStorage.getItem('AccessKey');
    if (token !== undefined) {
      const rawToken = JWT.decode(token);
      const now = (new Date().valueOf() / 1000);
      if (rawToken && (rawToken.exp > now)) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        setLoggedIn(true);
      }
    }
  }, []);

  const handleLogin = async (username, password, rememberMe) => {
    try {
      const nonceResponse = await axios.get('/data/login/init');
      const serverNonce = nonceResponse.data.nonce;
      if (serverNonce !== undefined) {
        const hash1 = sha256(`${username}:${password}`).toString(Hex);
        const clientNonce = getNonce();
        const hash2 = sha256(`${hash1}:${serverNonce}:${clientNonce}`).toString(Hex);
        const messageBody = {
          user: username,
          nonce: serverNonce,
          cnonce: clientNonce,
          credentials: hash2,
        };
        if (rememberMe) {
          messageBody.stayLoggedIn = true;
        }
        const loginResponse = await axios.post('/data/login', { messageBody });
        if (loginResponse.data.status && (loginResponse.data.status === true)) {
          axios.defaults.headers.common.Authorization = `Bearer ${loginResponse.data.token}`;
          localStorage.setItem('AccessKey', loginResponse.data.token);
          if (!loggedIn) {
            setLoggedIn(true);
          }
          return true;
        }
      }
    } catch (e) {
      if (loggedIn) {
        setLoggedIn(false);
      }
      return false;
    }
    return false;
  };

  const handleLogout = useCallback(() => {
    setLoggedIn(false);
    localStorage.removeItem('AccessKey');
  }, []);
  return (
    <Router>
      <Navigation onLogout={handleLogout} isLoggedIn={loggedIn} />
      <Switch>
        <Route
          path="/"
          exact
          render={() => <Redirect to="/home" />}
        />
        <Route path="/login">
          {() => {
            if (loggedIn) {
              return <Redirect to="/" />;
            }
            return <Login onLogin={handleLogin} />;
          }}
        </Route>
        <Route path="/password-recovery">
          {() => {
            if (loggedIn) {
              return <Redirect to="/" />;
            }
            return <PasswordRecovery />;
          }}
        </Route>
        <Route path="/home" component={Home} />
        <Route path="/overview">
          {() => {
            if (loggedIn) {
              return <Sensors onForbiddenAccess={handleLogout} />;
            }
            return <Redirect to="/" />;
          }}
        </Route>
        <Route path="/calendar">
          {() => {
            if (loggedIn) {
              return <Calendar />;
              // return <Home onForbiddenAccess={handleLogout} />;
            }
            return <Redirect to="/" />;
          }}
        </Route>
        <Route path="/todo">
          {() => {
            if (loggedIn) {
              return <ToDo onForbiddenAccess={handleLogout} />;
            }
            return <Redirect to="/" />;
          }}
        </Route>
        <Route path="/maintenace-manual">
          {() => {
            if (loggedIn) {
              return <Maintenance />;
            }
            return <Redirect to="/" />;
          }}
        </Route>
        <Route path="/account">
          {() => {
            if (loggedIn) {
              return <Account onForbiddenAccess={handleLogout} />;
            }
            return <Redirect to="/" />;
          }}
        </Route>
        <Route path="/" component={NotFound} />
      </Switch>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Router>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js/core';
import Hex from 'crypto-js/enc-hex';

export function ChangeUsernameDialog({
  openState,
  onClose,
  onCloseSuccessful,
  username,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [configuredName, setUsername] = useState('');
  const [configuredPassword, setPassword] = useState('');
  const getNonce = () => {
    const nonce = CryptoJS.lib.WordArray.random(4).toString(Hex).toUpperCase();
    return nonce;
  };
  const storeNewUsername = async () => {
    try {
      const nonceResponse = await axios.get('/data/login/init');
      const serverNonce = nonceResponse.data.nonce;
      if (serverNonce !== undefined) {
        const hash1 = sha256(`${username}:${configuredPassword}`).toString(Hex);
        const clientNonce = getNonce();
        const hash2 = sha256(`${hash1}:${serverNonce}:${clientNonce}`).toString(Hex);
        const hash3 = sha256(`${configuredName}:${configuredPassword}`).toString(Hex);
        const data = {
                      newusername: configuredName,
                      oldcredentials: hash2,
                      cnonce: clientNonce,
                      newcredentials: hash3,
                      };
        if (username !== '') {
            data.oldusername = username;
        }
        const request = await axios.post('/data/account/changeUsername', data);
        if (request.status === 200 && request.data.status === true) {
          if (request.data.token) {
              axios.defaults.headers.common.Authorization = `Bearer ${request.data.token}`;
              localStorage.setItem('AccessKey', request.data.token);
          }
          onCloseSuccessful();
        }
      }
    } catch (e) {
        onClose();
    }
  };

  const changeUsername = (event) => {
    setUsername(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

    return (
      <Dialog open={openState} onClose={onClose} fullScreen={fullScreen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Benutzername ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte gib einen neuen Benutzername und das aktuelle Passwort ein.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Benutzername"
            type="username"
            autoComplete="username"
            fullWidth
            onChange={changeUsername}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Passwort"
            type="password"
            autoComplete="password"
            fullWidth
            onChange={changePassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={storeNewUsername} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export function ChangeEmailDialog({ openState, onClose, onCloseSuccessful }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [newemail, setEmail] = useState('');
    const storeNewEmail = async () => {
      try {
        const data = { email: newemail };
        const request = await axios.post('/data/account/changeEmail', data);
        if (request.status === 200 && request.data.status === true) {
          onCloseSuccessful();
        }
      } catch {
          onClose();
      }
    };

    const changeEmail = (event) => {
        setEmail(event.target.value);
    };
    return (
      <Dialog open={openState} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">Email-Adresse ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte gib eine E-Mail ein, welche für das Wiederherstellen des Passworts verwendet werden soll.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email-Adresse"
            type="email"
            autoComplete="email"
            fullWidth
            onChange={changeEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={storeNewEmail} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export function ChangePasswordDialog({
    openState,
    onClose,
    onCloseSuccessful,
    username,
    mustKnowOldPassword,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [newpasswordrepeated, setNewPasswordRepeated] = useState('');
    const getNonce = () => {
      const nonceTable = [
        '0', '1', '2', '3',
        '4', '5', '6', '7',
        '8', '9', 'A', 'B',
        'C', 'D', 'E', 'F',
      ];
      let nonce = '';
      for (let i = 0; i < 8; i += 1) {
        const tableIndex = Math.round(Math.random() * 15);
        nonce += nonceTable[tableIndex];
      }
      return nonce;
    };
    const storeNewPassword = async () => {
      if (newpassword !== newpasswordrepeated) {
          return;
      }
      try {
        const nonceResponse = await axios.get('/data/login/init');
        const serverNonce = nonceResponse.data.nonce;
        if (serverNonce !== undefined) {
            const hash1 = sha256(`${username}:${oldpassword}`).toString(Hex);
            const clientNonce = getNonce();
            const hash2 = sha256(`${hash1}:${serverNonce}:${clientNonce}`).toString(Hex);
            const hash3 = sha256(`${username}:${newpassword}`).toString(Hex);
            const data = { newcredentials: hash3 };
            if (mustKnowOldPassword) {
                data.oldcredentials = hash2;
                data.cnonce = clientNonce;
            } else {
                data.user = username;
            }
            const request = await axios.post('/data/account/changePassword', data);
            if (request.status === 200 && request.data.status === true) {
            onCloseSuccessful();
            }
        }
      } catch {
          onClose();
      }
    };

    const changeOldPassword = (event) => {
        setOldPassword(event.target.value);
    };
    const changeNewPassword = (event) => {
        setNewPassword(event.target.value);
    };
    const changeNewPasswordRepeated = (event) => {
        setNewPasswordRepeated(event.target.value);
    };

    return (
      <Dialog open={openState} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">Passwort ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte gib ein neues Passwort ein.
          </DialogContentText>
          {mustKnowOldPassword
           ? (
             <TextField
               autoFocus
               margin="dense"
               id="pw-old"
               label="derzeitiges Passwort"
               type="password"
               autoComplete="current-password"
               onChange={changeOldPassword}
               fullWidth
             />
           )
          : null }
          <TextField
            margin="dense"
            id="pw-new"
            label="neues Passwort"
            type="password"
            autoComplete="new-password"
            onChange={changeNewPassword}
            fullWidth
          />
          <TextField
            margin="dense"
            id="pw-new-repeat"
            label="neues Passwort wiederholen"
            type="password"
            autoComplete="new-password"
            onChange={changeNewPasswordRepeated}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={storeNewPassword} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export function AddNewUserDialog({ openState, onClose, onStoreSuccessful }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [username, setUsername] = useState('');
    const [mailAddress, setMailAddress] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [newpasswordrepeated, setNewPasswordRepeated] = useState('');

    const changeUsername = (event) => {
        setUsername(event.target.value);
    };
    const changeMailAddress = (event) => {
        setMailAddress(event.target.value);
    };
    const changeNewPassword = (event) => {
        setNewPassword(event.target.value);
    };
    const changeNewPasswordRepeated = (event) => {
        setNewPasswordRepeated(event.target.value);
    };
    const storeNewUser = async () => {
      if (newpassword !== newpasswordrepeated) {
          return;
      }
      try {
          const hash = sha256(`${username}:${newpassword}`).toString(Hex);
          const data = { name: username, credentials: hash };
          if (mailAddress !== '') {
              data.mail = mailAddress;
          }
          const request = await axios.post('/data/account/createUser', data);
          if (request.status === 200) {
          onStoreSuccessful();
          onClose();
          }
      } catch {
          onClose();
      }
    };
    return (
      <Dialog open={openState} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">Benutzer erstellen</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="username"
            label="Benutername"
            type="text"
            autoComplete="username"
            onChange={changeUsername}
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="Email-Adresse"
            type="email"
            autoComplete="email"
            onChange={changeMailAddress}
            fullWidth
          />
          <TextField
            margin="dense"
            id="pw-new"
            label="Passwort"
            type="password"
            autoComplete="new-password"
            onChange={changeNewPassword}
            fullWidth
          />
          <TextField
            margin="dense"
            id="pw-new-repeat"
            label="Passwort wiederholen"
            type="password"
            autoComplete="new-password"
            onChange={changeNewPasswordRepeated}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={storeNewUser} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export function ConfirmDialog({
    openState,
    title,
    text,
    onConfirm,
    onCancel,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <Dialog open={openState} onClose={onCancel} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Abbrechen
          </Button>
          <Button onClick={onConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export function AdminEditUserDialog({
    openState,
    onClose,
    username,
    openNameEdit,
    openPasswordEdit,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const title = `Benutzer ${username} bearbeiten`;
    return (
      <Dialog open={openState} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogActions>
          <Button onClick={openNameEdit} color="primary">
            Benutzername ändern
          </Button>
          <Button onClick={openPasswordEdit} color="primary">
            Passwort ändern
          </Button>
          <Button onClick={onClose} color="primary">
            Schliessen
          </Button>
        </DialogActions>
      </Dialog>

    );
}

ChangeUsernameDialog.propTypes = {
    openState: PropTypes.instanceOf(Boolean).isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
    onCloseSuccessful: PropTypes.instanceOf(Function).isRequired,
    username: PropTypes.instanceOf(String),
  };

ChangeUsernameDialog.defaultProps = {
    username: '',
  };

ChangeEmailDialog.propTypes = {
    openState: PropTypes.instanceOf(Boolean).isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
    onCloseSuccessful: PropTypes.instanceOf(Function).isRequired,
  };

ChangePasswordDialog.propTypes = {
    openState: PropTypes.instanceOf(Boolean).isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
    onCloseSuccessful: PropTypes.instanceOf(Function).isRequired,
    username: PropTypes.instanceOf(String).isRequired,
    mustKnowOldPassword: PropTypes.instanceOf(Boolean).isRequired,
  };

AddNewUserDialog.propTypes = {
    openState: PropTypes.instanceOf(Boolean).isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
    onStoreSuccessful: PropTypes.instanceOf(Function).isRequired,
  };

ConfirmDialog.propTypes = {
    openState: PropTypes.instanceOf(Boolean).isRequired,
    onConfirm: PropTypes.instanceOf(Function).isRequired,
    onCancel: PropTypes.instanceOf(Function).isRequired,
    text: PropTypes.instanceOf(String).isRequired,
    title: PropTypes.instanceOf(String).isRequired,
  };

AdminEditUserDialog.propTypes = {
    openState: PropTypes.instanceOf(Boolean).isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
    openNameEdit: PropTypes.instanceOf(Function).isRequired,
    openPasswordEdit: PropTypes.instanceOf(Function).isRequired,
    username: PropTypes.instanceOf(String).isRequired,
  };

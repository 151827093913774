import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';

const lineColors = [
    '#F96142',
    '#249CA7',
    '#FBC931',
    '#ACD15D',
    '#083880',
];

highchartsMore(Highcharts);

function DataVisualisation(props) {
    const kDefaultOptions = {
        chart: {
            type: 'line',
        },
        colors: lineColors,
    };
    const kOptions = (props.chartOptions !== undefined) ? props.chartOptions : kDefaultOptions;
    const [mChartOptions, setChartOptions] = useState(undefined);
    useEffect(() => {
        const getData = async () => {
            try {
                setChartOptions({});
                const chartData = [];
                for (let idx = 0; idx < props.dataSources.length; idx += 1) {
                    const el = props.dataSources[idx];
                    const dataLimitation = (kOptions.chart.type === 'gauge');
                    // eslint-disable-next-line no-await-in-loop
                    const dataReq = await axios.get(`/data/overview/sensordata/${el.fieldNumber}?channel=${el.channelId}&range=${props.days}`);
                    if (dataReq.status === 200) {
                        const dataArray = dataReq.data.data;
                        if (dataLimitation) {
                            dataArray.reverse();
                            dataArray.length = 1;
                        }
                        const chartName = el.label ? el.label : '';
                        const selectedYAxis = el.yAxis ? el.yAxis : 0;
                        const seriesUnit = el.unit ? el.unit : '';
                        const seriesTooltip = `{point.x:%e.%m.%Y %H:%M}: {point.y:.2f} ${seriesUnit}`;
                        chartData.push({
                            name: chartName,
                            data: dataArray,
                            yAxis: selectedYAxis,
                            tooltip: {
                                headerFormat: '<b>{series.name}</b><br>',
                                pointFormat: seriesTooltip,
                            },
                        });
                    }
                }
                const renderData = kOptions;
                renderData.series = chartData;
                setChartOptions(renderData);
        } catch (e) {
            console.log(e);
        }
        };
        getData();
    }, [props.days]);
    if (!mChartOptions || !mChartOptions.chart) {
        return (
          <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
        );
    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={mChartOptions}
        containerProps={{
          style:
          {
              height: '100%',
              width: '100%',
              display: 'block',
              bottom: 0,
              top: 0,
              left: 0,
              right: 0,
              margin: '5px 15px 15px 0',
              overflow: 'hidden',
          },
        }}
      />
    );
}

DataVisualisation.propTypes = {
    dataSources: PropTypes.arrayOf(PropTypes.shape({
        channelId: PropTypes.instanceOf(Number).isRequired,
        fieldNumber: PropTypes.instanceOf(Number).isRequired,
        apikey: PropTypes.instanceOf(String).isRequired,
        label: PropTypes.instanceOf(String),
        yAxis: PropTypes.instanceOf(Number),
        unit: PropTypes.instanceOf(String),
    }).isRequired).isRequired,
    chartOptions: PropTypes.instanceOf(Object),
    days: PropTypes.instanceOf(Number).isRequired,
};

DataVisualisation.defaultProps = {
    chartOptions: undefined,
};

export default DataVisualisation;

import React from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core';
// import PropTypes from 'prop-types';
import Header from './Haus.jpg';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      height: '100%',
    },
    header: {
        width: '100vw',
        height: '50vh',
        objectFit: 'cover',
        objectPosition: '100% 50%',
    },
    '@media screen and (max-width: 992px)': {
        header: {
          width: '100vw',
          height: '30vh',
          objectFit: 'cover',
        },
    },
    '@media screen and (max-width: 600px)': {
        header: {
          width: '100vw',
          height: '40vh',
          objectFit: 'cover',
        },
    },
    headertext: {
        position: 'relative',
        top: '-16vh',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'black',
        color: 'white',
        paddingLeft: '20px',
        paddingRight: '20px',
        opacity: '0.6',
        width: 'fit-content',
    },
    headertextcontent: {
        fontSize: 'xx-large',
    },
  }));

function Home() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <CssBaseline />
        <div>
          <img src={Header} alt="header" className={classes.header} />
          <div className={classes.headertext}>
            <p className={classes.headertextcontent}>Casa Noldi</p>
          </div>
        </div>
      </div>
    );
}

/* Home.propTypes = {

};
*/
export default Home;

import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    TextField,
    Typography,
    CircularProgress,
    DialogContentText,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ImageIcon from '@material-ui/icons/Image';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FileBase64 from 'react-file-base64';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import './ToDoElements.css';
import axios from 'axios';
import { ImagePreview } from './ImagePreview';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ToDoAdd({ handleListRefresh }) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openState, setOpenState] = useState(false);
    const [openAddPictureState, setOpenAddPictureState] = useState(false);
    const [openFileDialogState, setOpenFileDialogState] = useState(false);
    const [openCameraState, setCameraOpenState] = useState(false);
    const [todoText, setToDoText] = useState('');
    const [imgURI, setImage] = useState('');
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [todoError, setTodoError] = useState(false);
    const [registeredUsers, setRegisteredUser] = useState([]);
    const [responsibleUser, setResponsibleUser] = useState('');

    const handleOpenState = () => {
        setImage(undefined);
        setOpenState(true);
    };
    const handleCloseState = () => {
        setOpenState(false);
    };
    const handleOpenAddPictureState = () => {
        setOpenAddPictureState(true);
    };
    const handleCloseAddPictureState = () => {
        setOpenAddPictureState(false);
    };
    const changeToDoText = (e) => {
        setToDoText(e.target.value);
    };

    const handleOpenCamera = () => {
        setImage(undefined);
        setCameraOpenState(true);
    };

    const handleDiscardCloseCamera = () => {
      setImage(undefined);
      setCameraOpenState(false);
    };

    const handleDiscardPhoto = () => {
      setImage(undefined);
    };

    const handleSaveCloseCamera = () => {
      setCameraOpenState(false);
      handleCloseAddPictureState();
    };

    const handleOpenFileDialog = () => {
      setImage(undefined);
      setOpenFileDialogState(true);
    };

    const handleDiscardCloseFileDialog = () => {
      setImage(undefined);
      setOpenFileDialogState(false);
    };

    const handleSaveCloseFileDialog = () => {
      setOpenFileDialogState(false);
      handleCloseAddPictureState();
    };

    const changeResponsibleUser = (e) => {
      setResponsibleUser(e.target.value);
    };

    const onSaveToDo = async () => {
      if (todoText === '') {
        setTodoError(true);
        return;
      }
      setTodoError(false);
      const data = {
        text: todoText,
        image: imgURI,
        responsible: responsibleUser,
      };
      setSaveInProgress(true);
      const saveReq = await axios.post('/data/todo/addEntry', data);
      if (saveReq.status === 200 && saveReq.data.status === true) {
        handleCloseState();
        handleListRefresh();
      } else {
        setTodoError(true);
      }
      setSaveInProgress(false);
    };

    const changeImageURI = (e) => {
      if ((e.type.indexOf('image/png') === 0) || (e.type.indexOf('image/jpeg') === 0)) {
        setImage(e.base64);
      }
    };

    const handleTakePhoto = (img) => {
        setImage(img);
    };

    useEffect(() => {
      const getUserList = async () => {
        const userList = await axios.get('/data/todo/users');
        if (userList.status === 200) {
          const userListOptions = [];
          userList.data.users.forEach((userInfo) => {
            userListOptions.push(<MenuItem value={userInfo.id}>{userInfo.name}</MenuItem>);
          });
          setRegisteredUser(userListOptions);
        }
      };
      getUserList();
    }, []);

    return (
      <div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleOpenState}
        >
          Aufgabe hinzufügen
        </Button>
        <Dialog open={openState} onClose={handleCloseState} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogTitle id="form-dialog-title">
            Aufgabe hinzufügen
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="todotext"
              label="Aufgabe"
              type="text"
              fullWidth
              margin="dense"
              onChange={changeToDoText}
              required
              error={todoError}
              style={{ marginBottom: '20px' }}
            />
            <InputLabel>Zuständigkeit</InputLabel>
            <Select
              id="responsibleuser"
              fullWidth
              margin="dense"
              onChange={changeResponsibleUser}
              style={{ marginBottom: '20px' }}
            >
              {registeredUsers}
            </Select>
            {
              (imgURI && !openCameraState && !openAddPictureState)
              ? <img src={imgURI} alt="Bild zu Aufgabe" style={{ width: '100%' }} />
              : null
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenAddPictureState} color="primary">
              { (imgURI) ? 'Bild ändern' : 'Bild hinzufügen' }
            </Button>
            <Button onClick={onSaveToDo} color="primary" disabled={saveInProgress}>
              Speichern
            </Button>
            <Button onClick={handleCloseState} color="primary">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAddPictureState} onClose={handleCloseAddPictureState} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogTitle id="form-dialog-title">
            Bild hinzufügen
          </DialogTitle>
          <Button onClick={handleOpenCamera} color="primary">
            Mit Kamera
          </Button>
          <Button onClick={handleOpenFileDialog} color="primary">
            Aus Datei
          </Button>
          <Button onClick={handleCloseAddPictureState} color="primary">
            Zurück
          </Button>
        </Dialog>
        <Dialog open={openCameraState} onClose={handleDiscardCloseCamera} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogContent>
            <div style={{ width: '100%' }}>
              {
                  (imgURI)
                  ? <ImagePreview dataUri={imgURI} isFullscreen={false} />
                  : (
                    <Camera
                      isFullscreen={false}
                      idealFacingMode={FACING_MODES.ENVIRONMENT}
                      imageType={IMAGE_TYPES.JPG}
                      imageCompression={0.8}
                      isImageMirror={false}
                      onTakePhotoAnimationDone={handleTakePhoto}
                    />
                  )
              }
            </div>
          </DialogContent>
          <DialogActions>
            {
              (imgURI)
              ? (
                <Button onClick={handleDiscardPhoto} color="primary">
                  Erneut versuchen
                </Button>
                )
              : null
            }
            {
              (imgURI)
              ? (
                <Button onClick={handleSaveCloseCamera} color="primary">
                  Bild übernehmen
                </Button>
              )
              : null
            }
            <Button onClick={handleDiscardCloseCamera} color="primary">
              Zurück
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openFileDialogState} onClose={handleDiscardCloseFileDialog} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogContent>
            {
              (!imgURI)
              ? (
                <FileBase64
                  multiple={false}
                  onDone={changeImageURI}
                />
                )
              : <img src={imgURI} alt="Bild zu Aufgabe" style={{ width: '100%' }} />
            }
          </DialogContent>
          <DialogActions>
            {
              (imgURI)
              ? (
                <Button onClick={handleDiscardPhoto} color="primary">
                  Anderes Bild auswählen
                </Button>
                )
              : null
            }
            {
              (imgURI)
              ? (
                <Button onClick={handleSaveCloseFileDialog} color="primary">
                  Bild übernehmen
                </Button>
              )
              : null
            }
            <Button onClick={handleDiscardCloseFileDialog} color="primary">
              Zurück
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

ToDoAdd.propTypes = {
  handleListRefresh: PropTypes.instanceOf(Function).isRequired,
};

function ToDoCompletedDialog({
  openState,
  todoText,
  closeHandle,
  handleToDoCompleted,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseAndCompleteTask = () => {
    handleToDoCompleted();
    closeHandle();
  };

  return (
    <Dialog open={openState} onClose={closeHandle} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">
        Aufgabe abgeschlossen?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {todoText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAndCompleteTask} color="primary">
          Ja
        </Button>
        <Button onClick={closeHandle} color="primary">
          Nein
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ToDoCompletedDialog.propTypes = {
  openState: PropTypes.instanceOf(Boolean).isRequired,
  todoText: PropTypes.instanceOf(String).isRequired,
  closeHandle: PropTypes.instanceOf(Function).isRequired,
  handleToDoCompleted: PropTypes.instanceOf(Function).isRequired,
};

function ToDoPreview({
  todoText,
  imgURI,
  openState,
  closeHandle,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closePreview = () => {
    closeHandle();
  };

  return (
    <Dialog open={openState} onClose={closePreview} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">
        {todoText}
      </DialogTitle>
      <DialogContent>
        <img src={imgURI} alt="Bild zu Aufgabe" style={{ width: '100%' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closePreview} color="primary">
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ToDoPreview.propTypes = {
  todoText: PropTypes.instanceOf(String).isRequired,
  imgURI: PropTypes.instanceOf(String).isRequired,
  openState: PropTypes.instanceOf(Boolean).isRequired,
  closeHandle: PropTypes.instanceOf(Function).isRequired,
};

function ToDoItem({
  taskId,
  task,
  issuer,
  forceReload,
  image,
}) {
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [todoDoneDialog, setToDoDoneDialog] = useState(false);
  const classes = useStyles();

  const handleOpenImagePreview = () => {
    setOpenImagePreview(true);
  };

  const handleCloseImagePreview = () => {
    setOpenImagePreview(false);
  };

  const handleTodoDoneDialogOpen = () => {
    setToDoDoneDialog(true);
  };
  const handleTodoDoneDialogClose = () => {
    setToDoDoneDialog(false);
  };

  const handleTaskCompleted = () => {
    async function completeTask() {
      const data = { id: taskId };
      const state = await axios.post('/data/todo/taskComplete', data);
      if (state.status === 200 && state.data.status === true) {
        forceReload();
      }
    }
    completeTask();
  };
  return (
    <ListItem>
      <Typography className={classes.heading}>{ task }</Typography>
      <Typography className={classes.secondaryHeading}>
        { issuer }
      </Typography>
      <ListItemSecondaryAction>
        { (image !== undefined)
        ? (
          <IconButton edge="end" aria-label="edit" onClick={handleOpenImagePreview}>
            <ImageIcon />
          </IconButton>
          )
        : null }
        { (image !== undefined)
        ? (
          <ToDoPreview
            todoText={task}
            imgURI={image}
            openState={openImagePreview}
            closeHandle={handleCloseImagePreview}
          />
          )
        : null }
        <IconButton edge="end" aria-label="edit" onClick={handleTodoDoneDialogOpen}>
          <CheckBoxOutlineBlankIcon />
        </IconButton>
      </ListItemSecondaryAction>
      <ToDoCompletedDialog
        openState={todoDoneDialog}
        todoText={task}
        closeHandle={handleTodoDoneDialogClose}
        handleToDoCompleted={handleTaskCompleted}
      />
    </ListItem>
  );
}

ToDoItem.propTypes = {
  taskId: PropTypes.instanceOf(String).isRequired,
  task: PropTypes.instanceOf(String).isRequired,
  issuer: PropTypes.instanceOf(String).isRequired,
  forceReload: PropTypes.instanceOf(Function).isRequired,
  image: PropTypes.instanceOf(String),
};

ToDoItem.defaultProps = {
  image: undefined,
};

export function ToDoList({ onForbiddenAccess, listStatus }) {
  const [ToDoElements, setTodoElements] = useState([]);
  const [internalListStatus, setInternalListStatus] = useState(0);
  const classes = useStyles();

  const handleInternalListStatus = () => {
    setInternalListStatus(internalListStatus + 1);
  };

  useEffect(() => {
    async function getToDoList() {
      setTodoElements([(<CircularProgress size={24} className={classes.buttonProgress} />)]);
      try {
        const temporaryList = [];
        const request = await axios.get('/data/todo/list');
        if (request.status === 200 && request.data.todos && request.data.todos.length > 0) {
          request.data.todos.forEach((el) => {
            const taskImage = ((el.image !== '') && (el.image !== null)) ? el.image : undefined;
            temporaryList.push((
              <ToDoItem taskId={el.id} task={el.text} issuer={el.issuer} image={taskImage} forceReload={handleInternalListStatus} />
            ));
          });
          setTodoElements(temporaryList);
        } else if (request.status === 200) {
          setTodoElements([]);
        }
      } catch (e) {
        onForbiddenAccess();
      }
    }
    getToDoList();
  }, [listStatus, internalListStatus]);

  return (
    <List>
      {ToDoElements}
    </List>
  );
}

ToDoList.propTypes = {
  onForbiddenAccess: PropTypes.instanceOf(Function).isRequired,
  listStatus: PropTypes.instanceOf(Number).isRequired,
};

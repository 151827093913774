import {
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import WikiView from '../../components/wiki/WikiView';
import MaintenanceNavigator from './MaintenanceNavigator';
import MaintenanceDialogToEdit from './MaintenanceDialogToEdit';
import MaintenanceDialogToDelete from './MaintenanceDialogToDelete';
import MaintenanceDialogToDiscard from './MaintenanceDialogToDiscard';
import MaintenanceDialogToSave from './MaintenanceDialogToSave';
import MaintenanceDialogToCreate from './MaintenanceDialogToCreate';
// import PropTypes from 'prop-types';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '50%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

function Maintenance() {
    const classes = useStyles();
    const [mVisuState, setVisuState] = useState(0); // 0 = Overview, 1 = ArticleView, 2 = ArticleEdit
    const [mCurrentArticleData, setCurrentArticleData] = useState({ id: undefined, title: '', content: '' });
    const [mShowDeleteArticleDialog, setShowDeleteArticleDialog] = useState(false);
    const [mArticleList, setArticleList] = useState();
    const [mDialogToEditVisible, setDialogToEditVisible] = useState(false);
    const [mDialogToSaveVisible, setDialogToSaveVisible] = useState(false);
    const [mDialogToDiscardVisible, setDialogToDiscardVisible] = useState(false);
    const [mDialogToCreateVisible, setDialogToCreateVisible] = useState(false);
    const [mArticleIsNotStored, setArticleIsNotStored] = useState(false);

    const handleOpenConfirmDeleteArticle = () => {
      setShowDeleteArticleDialog(true);
    };
    const handleCloseConfirmDeleteArticle = () => {
      setShowDeleteArticleDialog(false);
    };

    const openDialogToSave = () => {
      setDialogToSaveVisible(true);
    };
    const closeDialogToSave = () => {
      setDialogToSaveVisible(false);
    };

    const openDialogToCreate = () => {
      setDialogToCreateVisible(true);
    };
    const closeDialogToCreate = () => {
      setDialogToCreateVisible(false);
    };

    const openDialogToDiscard = () => {
      setDialogToDiscardVisible(true);
    };
    const closeDialogToDiscard = () => {
      setDialogToDiscardVisible(false);
    };

    const openDialogToEdit = () => {
      setDialogToEditVisible(true);
    };

    const closeDialogToEdit = () => {
      setDialogToEditVisible(false);
    };

    const changeToEdit = () => {
      closeDialogToEdit();
      closeDialogToDiscard();
      closeDialogToSave();
      setVisuState(2);
    };

    const onCreateNewArticle = (title) => {
      const currentArticle = mCurrentArticleData;
      currentArticle.title = ((title !== undefined) && (title !== ''))
                           ? title
                           : '';
      currentArticle.content = '[//]: # "Hier Text verfassen"';
      setCurrentArticleData(currentArticle);
      closeDialogToCreate();
      changeToEdit();
    };

    const unstoredChangesSignal = (val) => {
      setArticleIsNotStored(val);
    };

    const saveArticle = (articleData) => {
      const saveRequest = async (articleObj) => {
        const msgBody = {
                          title: articleObj.title,
                          content: articleObj.content,
                        };
        if (articleObj.id !== undefined && articleObj.id !== '') {
          msgBody.articleId = articleObj.id;
        }
        const saveRes = await axios.post('/data/article/save', msgBody);
        if (saveRes) {
          const currentArticle = mCurrentArticleData;
          currentArticle.id = saveRes.data.articleId;
          setCurrentArticleData(currentArticle);
          unstoredChangesSignal(false);
        }
      };
      saveRequest(articleData);
    };

    const clearView = () => {
      const currentArticle = mCurrentArticleData;
      currentArticle.title = '';
      currentArticle.content = '';
      currentArticle.id = undefined;
      setCurrentArticleData(currentArticle);
      setVisuState(0);
      closeDialogToEdit();
      closeDialogToDiscard();
      closeDialogToSave();
    };

    const onDeleteArticle = () => {
      const sendDeleteRequest = async () => {
        const currentArticle = mCurrentArticleData;
        await axios.post('/data/article/delete', { articleId: mCurrentArticleData.id });
        currentArticle.title = '';
        currentArticle.content = '';
        currentArticle.id = undefined;
        setCurrentArticleData(currentArticle);
        handleCloseConfirmDeleteArticle();
        setVisuState(-1);
        clearView();
      };
      sendDeleteRequest();
    };

    const readArticleData = async (articleId) => {
      try {
        const articleDataReq = await axios.get(`/data/article/${articleId}`);
        const articleData = articleDataReq.data;
        const currentArticle = mCurrentArticleData;
        currentArticle.id = articleData.id;
        currentArticle.content = articleData.content;
        currentArticle.title = articleData.title;
        setCurrentArticleData(currentArticle);
        return true;
      } catch (e) {
        return false;
      }
    };

    const saveCurrentArticleData = () => {
      const articleData = mCurrentArticleData;
      saveArticle(articleData);
      setArticleIsNotStored(false);
      closeDialogToSave();
    };

    useEffect(() => {
      const getArticleList = async () => {
        const articles = await axios.get('/data/article/list');
        if (articles) {
          setArticleList(articles.data.list);
        }
      };
      if (mVisuState === 0) {
        getArticleList();
      }
    }, [mVisuState]);

    const kArticleFieldsList = [
      {
        field: 'id',
        headerName: '#',
        width: 90,
        hide: true,
      },
      {
        field: 'title',
        headerName: 'Titel',
        flex: 9,
      },
      {
        field: 'actions',
        headerName: 'Aktionen',
        minWidth: 130,
        sortable: false,
        renderCell: (params) => {
          const onEditClick = () => {
            const articleId = params.api.getCellValue(params.id, 'id');
            const currentArticle = mCurrentArticleData;
            currentArticle.id = articleId;
            const setEdit = async () => {
              setCurrentArticleData(currentArticle);
              if (await readArticleData(articleId)) {
                openDialogToEdit();
              }
            };
            setEdit();
          };
          const onDeleteClick = () => {
            const articleId = params.api.getCellValue(params.id, 'id');
            const currentArticle = mCurrentArticleData;
            currentArticle.id = articleId;
            setCurrentArticleData(currentArticle);
            handleOpenConfirmDeleteArticle();
          };
          const onViewClick = () => {
            const articleId = params.api.getCellValue(params.id, 'id');
            const currentArticle = mCurrentArticleData;
            currentArticle.id = articleId;
            const setView = async () => {
              setCurrentArticleData(currentArticle);
              if (await readArticleData(articleId)) {
                setVisuState(1);
              }
            };
            setView();
          };
          return (
            <strong>
              <IconButton
                edge="end"
                aria-label="view"
                onClick={onViewClick}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={onEditClick}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={onDeleteClick}
              >
                <DeleteIcon />
              </IconButton>
            </strong>
          );
        },
      },
    ];
    if (mArticleList === undefined) {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <CssBaseline />
            <Container maxWidth="lg" className={classes.container}>
              <Paper style={{ flexGrow: 1, height: '80vh' }}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  }}
                >
                  <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
              </Paper>
            </Container>
          </main>
        </div>
      );
    }
    switch (mVisuState) {
      case -1:
      case 0:
        return (
          <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <CssBaseline />
              <Container maxWidth="lg" className={classes.container}>
                <MaintenanceNavigator createButton={openDialogToCreate} />
                <Paper style={{ flexGrow: 1, height: '80vh' }}>
                  <DataGrid
                    rows={mArticleList}
                    columns={kArticleFieldsList}
                    pageSize={5}
                    disableMultipleColumnsSorting
                    disableSelectionOnClick
                    filterModel={{
                      items: [{ columnField: 'title', operatorValue: 'contains' }],
                    }}
                  />
                  <MaintenanceDialogToCreate open={mDialogToCreateVisible} cancel={closeDialogToCreate} ok={onCreateNewArticle} />
                  <MaintenanceDialogToDelete open={mShowDeleteArticleDialog} cancel={handleCloseConfirmDeleteArticle} ok={onDeleteArticle} />
                  <MaintenanceDialogToEdit open={mDialogToEditVisible} cancel={closeDialogToEdit} ok={changeToEdit} />
                </Paper>
              </Container>
            </main>
          </div>
        );
      case 1:
        return (
          <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <CssBaseline />
              <Container maxWidth="lg" className={classes.container}>
                <MaintenanceNavigator backButton={clearView} editButton={openDialogToEdit} title={mCurrentArticleData.title} />
                <Paper style={{ padding: '20px' }}>
                  <WikiView articleData={mCurrentArticleData} readOnly />
                </Paper>
              </Container>
              <MaintenanceDialogToEdit open={mDialogToEditVisible} cancel={closeDialogToEdit} ok={changeToEdit} />
            </main>
          </div>
        );
      case 2:
        return (
          <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <CssBaseline />
              <Container maxWidth="lg" className={classes.container}>
                <MaintenanceNavigator backButton={openDialogToDiscard} saveButton={openDialogToSave} title={mCurrentArticleData.title} isNotStored={mArticleIsNotStored} />
                <Paper style={{ padding: '20px' }}>
                  <WikiView articleData={mCurrentArticleData} saveFunction={saveArticle} setUnstoredChanges={unstoredChangesSignal} readOnly={false} />
                </Paper>
              </Container>
              <MaintenanceDialogToSave open={mDialogToSaveVisible} cancel={closeDialogToSave} ok={saveCurrentArticleData} />
              <MaintenanceDialogToDiscard open={mDialogToDiscardVisible} cancel={closeDialogToDiscard} ok={clearView} />
            </main>
          </div>
        );
      default:
        break;
    }
}

Maintenance.propTypes = {

};

export default Maintenance;

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
 Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery,
} from '@material-ui/core';

function MaintenanceDialogToDiscard(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={props.open}
      onClose={props.cancel}
      aria-labelledby="form-dialog-edit-article"
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">Artikel schliessen und ungespeicherte Änderungen verwerfen?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Möchtest du die Bearbeitung des Artikels beenden und nicht gespeicherte Änderungen verwerfen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancel} color="primary">
          Nein
        </Button>
        <Button onClick={props.ok} color="secondary">
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MaintenanceDialogToDiscard.propTypes = {
  cancel: PropTypes.instanceOf(Function).isRequired,
  ok: PropTypes.instanceOf(Function).isRequired,
  open: PropTypes.instanceOf(Boolean).isRequired,
};

export default MaintenanceDialogToDiscard;

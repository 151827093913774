import {
    Container,
    CssBaseline,
  Grid,
  makeStyles,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
}
  from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
  AddNewUserDialog,
  AdminEditUserDialog,
  ChangeEmailDialog,
  ChangePasswordDialog,
  ChangeUsernameDialog,
  ConfirmDialog,
} from '../../components/adminpaneldialogs/AdminpanelDialogs';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 340,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '50%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

export default function Account({ onForbiddenAccess }) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openUsernameDialog, setUsernameDialogOpen] = useState(false);
  const [openEmailDialog, setEmailDialogOpen] = useState(false);
  const [openPassword, setPasswordOpen] = useState(false);
  const [openAdminPanel, setAdminPanelOpen] = useState(false);
  const [openAdminPanelAddUser, setAdminPanelAddUser] = useState(false);
  const [accountDetails, setAccoutDetails] = useState({ name: '?', eMailRegistered: false, activeAccounts: undefined });
  const [adminOptionChangedCount, setAdminOptionChanged] = useState(0);
  const [confirmDeleteUserOpen, setConfirmDeleteUserOpen] = useState(false);
  const [adminPanelSelectedUser, setAdminPanelSelectedUser] = useState('');
  const [editAdminUserOpen, setEditAdminUserOpen] = useState(false);
  const [adminPasswordEdit, setEditPasswordAdminOpen] = useState(false);
  const [adminUsernameEdit, setEditUsernameAdminUserOpen] = useState(false);

  const [mUserlist, setUserlist] = useState([]);

  const increaseAdminOptionCount = () => {
    setAdminOptionChanged(adminOptionChangedCount + 1);
  };

  useEffect(() => {
    async function getUserDetails() {
      setAccoutDetails({ name: '?', eMailRegistered: false });
      try {
        const request = await axios.get('/data/account/info');
        if (request.status === 200) {
          setAccoutDetails({ name: request.data.user, eMailRegistered: request.data.email, activeAccounts: request.data.numofaccouts });
        }
      } catch (e) {
        onForbiddenAccess();
      }
    }
    getUserDetails();
  }, [adminOptionChangedCount]);

  const handleUsernameDialogOpen = () => {
    setUsernameDialogOpen(true);
  };

  const handleUsernameDialogClose = () => {
    setUsernameDialogOpen(false);
  };

  const handleUsernameDialogCloseSuccessful = () => {
    setUsernameDialogOpen(false);
    increaseAdminOptionCount();
  };

  const handleEmailDialogOpen = () => {
    setEmailDialogOpen(true);
  };

  const handleEmailDialogClose = () => {
    setEmailDialogOpen(false);
  };

  const handleEmailDialogCloseSuccessful = () => {
    setEmailDialogOpen(false);
    increaseAdminOptionCount();
  };

  const handlePasswordOpen = () => {
    setPasswordOpen(true);
  };

  const handlePasswordClose = () => {
    setPasswordOpen(false);
  };

  const handlePasswordCloseSuccessful = () => {
    setPasswordOpen(false);
    increaseAdminOptionCount();
  };

  const handleAdminPanelOpen = () => {
    setAdminPanelOpen(true);
  };

  const handleAdminPanelClose = () => {
    setAdminPanelOpen(false);
  };

  const handleAdminOpenUserAddPanel = () => {
    setAdminPanelAddUser(true);
  };

  const handleAdminCloseUserAddPanel = () => {
    setAdminPanelAddUser(false);
  };

  const handleOpenConfirmDeleteUserOpen = () => {
    setConfirmDeleteUserOpen(true);
  };
  const handleCloseConfirmDeleteUserOpen = () => {
    setConfirmDeleteUserOpen(false);
  };

  const handleOpenAdminUserOpen = () => {
    setEditAdminUserOpen(true);
  };
  const handleCloseAdminUserOpen = () => {
    setEditAdminUserOpen(false);
  };

  const handleOpenAdminUsernameEdit = () => {
    setEditUsernameAdminUserOpen(true);
  };

  const handleCloseAdminUsernameEdit = () => {
    setEditUsernameAdminUserOpen(false);
  };

  const handleCloseAdminUsernameEditSuccessful = () => {
    setEditUsernameAdminUserOpen(false);
    increaseAdminOptionCount();
  };
  const handleOpenAdminPasswordEdit = () => {
    setEditPasswordAdminOpen(true);
  };

  const handleCloseAdminPasswordEdit = () => {
    setEditPasswordAdminOpen(false);
  };

  const handleCloseAdminPasswordEditSuccessful = () => {
    setEditPasswordAdminOpen(false);
    increaseAdminOptionCount();
  };

  const deleteUser = () => {
    async function issueDelete() {
      try {
        const data = { name: adminPanelSelectedUser };
        const request = await axios.post('/data/account/deleteUser', data);
        if (request.status === 200) {
          increaseAdminOptionCount();
          handleCloseConfirmDeleteUserOpen();
        }
      } catch (e) {
        console.log('error');
      }
    }
    issueDelete();
  };

  useEffect(() => {
    async function getUserlist() {
      try {
        const request = await axios.get('/data/account/userlist');
        if (request.status === 200) {
          if (request.data.users && (request.data.users.length > 0)) {
            setUserlist(request.data.users);
          }
        }
      } catch (e) {
        console.log('error');
      }
    }
    if (openAdminPanel) {
      getUserlist();
    }
  }, [openAdminPanel, adminOptionChangedCount]);

  const kUserlistColumn = [
    {
      field: 'id',
      headerName: '#',
      width: 90,
      hide: true,
    },
    { field: 'name', headerName: 'Benutzer', width: 200 },
    { field: 'permissions', headerName: 'Rechtemaske', width: 150 },
    {
      field: 'email',
      headerName: 'Email',
      width: 90,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 95,
      sortable: false,
      renderCell: (params) => {
        const onEditClick = () => {
          const username = params.api.getCellValue(params.id, 'name');
          setAdminPanelSelectedUser(username);
          handleOpenAdminUserOpen();
        };
        const onDeleteClick = () => {
          const username = params.api.getCellValue(params.id, 'name');
          setAdminPanelSelectedUser(username);
          handleOpenConfirmDeleteUserOpen();
        };
        return (
          <strong>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={onEditClick}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </IconButton>
          </strong>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={fixedHeightPaper}>
                { (accountDetails.name === '?')
                ? (<CircularProgress size={24} className={classes.buttonProgress} />)
                : (
                  <List>
                    <ListItem>
                      <Typography className={classes.heading}>Benutzername:</Typography>
                      <Typography className={classes.secondaryHeading}>
                        { accountDetails.name }
                      </Typography>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="edit" onClick={handleUsernameDialogOpen}>
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                      <ChangeUsernameDialog
                        openState={openUsernameDialog}
                        onClose={handleUsernameDialogClose}
                        username={accountDetails.name}
                        onCloseSuccessful={handleUsernameDialogCloseSuccessful}
                      />
                    </ListItem>
                    <ListItem>
                      <Typography className={classes.heading}>Email:</Typography>
                      { accountDetails.eMailRegistered
                        ? <Typography className={classes.secondaryHeading}>Registriert</Typography>
                        : <Typography className={classes.secondaryHeading}>Keine Addresse hinterlegt</Typography>}
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="edit" onClick={handleEmailDialogOpen}>
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                      <ChangeEmailDialog
                        openState={openEmailDialog}
                        onClose={handleEmailDialogClose}
                        onCloseSuccessful={handleEmailDialogCloseSuccessful}
                      />
                    </ListItem>
                    <ListItem>
                      <Typography className={classes.heading}>Passwort:</Typography>
                      <Typography className={classes.secondaryHeading}>********</Typography>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="edit" onClick={handlePasswordOpen}>
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                      <ChangePasswordDialog
                        openState={openPassword}
                        onClose={handlePasswordClose}
                        onCloseSuccessful={handlePasswordCloseSuccessful}
                        username={accountDetails.name}
                        mustKnowOldPassword
                      />
                    </ListItem>
                    { accountDetails.activeAccounts !== undefined
                    ? (
                      <ListItem>
                        <Typography className={classes.heading}>Aktive Konten:</Typography>
                        <Typography className={classes.secondaryHeading}>{ accountDetails.activeAccounts }</Typography>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={handleAdminPanelOpen}>
                            <EditIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <Dialog
                          open={openAdminPanel}
                          onClose={handleAdminPanelClose}
                          aria-labelledby="form-dialog-title"
                          fullScreen={fullScreen}
                        >
                          <DialogTitle id="form-dialog-title">Admin Panel</DialogTitle>
                          <DialogContent
                            style={{ width: '600px', height: '480px' }}
                          >
                            <div style={{ flexGrow: 1, height: '100%' }}>
                              <DataGrid
                                rows={mUserlist}
                                columns={kUserlistColumn}
                                pageSize={5}
                                disableColumnMenu
                                disableSelectionOnClick
                                disableMultipleColumnsSorting
                              />
                            </div>
                            <ConfirmDialog
                              openState={confirmDeleteUserOpen}
                              onCancel={handleCloseConfirmDeleteUserOpen}
                              onConfirm={deleteUser}
                              text="Soll der Benutzer wirklich gelöscht werden?"
                              title="Benutzer löschen"
                            />
                            <AdminEditUserDialog
                              openState={editAdminUserOpen}
                              onClose={handleCloseAdminUserOpen}
                              username={adminPanelSelectedUser}
                              openNameEdit={handleOpenAdminUsernameEdit}
                              openPasswordEdit={handleOpenAdminPasswordEdit}
                            />
                            <ChangePasswordDialog
                              openState={adminPasswordEdit}
                              onClose={handleCloseAdminPasswordEdit}
                              onCloseSuccessful={handleCloseAdminPasswordEditSuccessful}
                              username={adminPanelSelectedUser}
                              mustKnowOldPassword={false}
                            />
                            <ChangeUsernameDialog
                              openState={adminUsernameEdit}
                              onClose={handleCloseAdminUsernameEdit}
                              onCloseSuccessful={handleCloseAdminUsernameEditSuccessful}
                              username={adminPanelSelectedUser}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleAdminOpenUserAddPanel} color="primary">
                              Benutzer hinzufügen
                            </Button>
                            <AddNewUserDialog openState={openAdminPanelAddUser} onClose={handleAdminCloseUserAddPanel} onStoreSuccessful={increaseAdminOptionCount} />
                            <Button onClick={handleAdminPanelClose} color="primary">
                              OK
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </ListItem>
                      )
                    : <ListItem /> }
                  </List>
              )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

Account.propTypes = {
    onForbiddenAccess: PropTypes.instanceOf(Function).isRequired,
};

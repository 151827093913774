import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
 Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, useMediaQuery,
} from '@material-ui/core';

function MaintenanceDialogToCreate(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mArticleTitle, setArticleTitle] = useState('');

  const setTitle = (e) => {
    setArticleTitle(e.target.value);
  };

  const createArticle = () => {
    props.ok(mArticleTitle);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.cancel}
      aria-labelledby="form-dialog-edit-article"
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">Neuen Artikel erstellen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Titel eingeben
        </DialogContentText>
        <Input onChange={setTitle} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancel} color="primary">
          Abbrechen
        </Button>
        <Button onClick={createArticle} color="secondary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MaintenanceDialogToCreate.propTypes = {
  cancel: PropTypes.instanceOf(Function).isRequired,
  ok: PropTypes.instanceOf(Function).isRequired,
  open: PropTypes.instanceOf(Boolean).isRequired,
};

export default MaintenanceDialogToCreate;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        TextField,
        useMediaQuery,
        useTheme,
        } from '@material-ui/core';
import {
        MuiPickersUtilsProvider,
        KeyboardDatePicker,
        } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import axios from 'axios';

// import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';

export default function CalendarDialog({
  open,
  onClose,
  scheduleAdded,
  editableScheduleObject,
}) {
    const theme = useTheme();
    // const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [mScheduleTitle, setScheduleTitle] = useState('');
    const [mStartDate, setStartDate] = useState(DateTime.now());
    const [mEndDate, setEndDate] = useState(DateTime.now().plus({ days: 7 }));
    const [mScheduleTitleError, setScheduleTitleError] = useState(false);
    const [mSaveInProgress, setSaveInProgress] = useState(false);

    const changeScheduleTitle = (e) => {
        setScheduleTitle(e.target.value);
        setScheduleTitleError(false);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e);
    };

    const onSaveSchedule = () => {
        if (mScheduleTitle === '') {
          setScheduleTitleError(true);
          return;
        }
        const saveSchedule = async () => {
          setSaveInProgress(true);
          const scheduleObject = {
            title: mScheduleTitle,
            startDate: mStartDate.valueOf(),
            endDate: mEndDate.valueOf(),
          };
          const url = (editableScheduleObject.id)
                      ? '/data/calendar/editentry'
                      : '/data/calendar/addentry';
          if (editableScheduleObject.id) {
            scheduleObject.id = editableScheduleObject.id;
          }
          const request = await axios.post(url, scheduleObject);
          if (request.status === 200) {
            if (request.data.status === true) {
              setSaveInProgress(false);
              scheduleAdded();
              onClose();
            }
          }
          setScheduleTitleError();
          setSaveInProgress(false);
        };
        saveSchedule();
    };

    useEffect(() => {
      if (editableScheduleObject.startDate && editableScheduleObject.endDate && editableScheduleObject.title) {
        setStartDate(DateTime.fromMillis(editableScheduleObject.startDate));
        setEndDate(DateTime.fromMillis(editableScheduleObject.endDate));
        setScheduleTitle(editableScheduleObject.title);
      }
    }, [editableScheduleObject]);

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">
          Termin hinzufügen
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="scheduletitle"
            label="Bezeichnung"
            type="text"
            fullWidth
            margin="dense"
            value={mScheduleTitle}
            onChange={changeScheduleTitle}
            required
            error={mScheduleTitleError}
            style={{ marginBottom: '20px' }}
          />
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="dialog"
              format="dd.MM.yyyy"
              margin="normal"
              id="startdate-picker-inline"
              label="Startdatum"
              value={mStartDate}
              onChange={(date) => handleStartDateChange(date)}
              required
              fullWidth
              KeyboardButtonProps={{
                  'aria-label': 'change start date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="dialog"
              format="dd.MM.yyyy"
              margin="normal"
              id="enddate-picker-inline"
              label="Enddatum"
              value={mEndDate}
              onChange={(date) => handleEndDateChange(date)}
              required
              fullWidth
              style={{ float: 'right' }}
              KeyboardButtonProps={{
                  'aria-label': 'change end date',
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSaveSchedule} color="primary" disabled={mSaveInProgress}>
            Speichern
          </Button>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    );
}

CalendarDialog.defaultProps = {
  editableScheduleObject: {},
};

CalendarDialog.propTypes = {
    open: PropTypes.instanceOf(Boolean).isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
    scheduleAdded: PropTypes.instanceOf(Function).isRequired,
    editableScheduleObject: PropTypes.instanceOf(Object),
};

export function ScheduleDeleteDialog({
  open,
  onClose,
  scheduleDetails,
  scheduleDeleted,
}) {
  const theme = useTheme();
  // const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [mTaskInProgress, setTaskInProgress] = useState(false);

  const onDeleteSchedule = () => {
    const deleteSchedule = async () => {
      const data = {
        id: scheduleDetails,
      };
      setTaskInProgress(true);
      const request = await axios.post('/data/calendar/removeentry', data);
      if (request.status === 200 && request.data.status) {
        scheduleDeleted();
        onClose();
      }
      setTaskInProgress(false);
    };
    deleteSchedule();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">
        Termin löschen ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={onDeleteSchedule} color="secondary" disabled={mTaskInProgress}>
          Ja
        </Button>
        <Button onClick={onClose} color="primary">
          Nein
        </Button>
      </DialogActions>
    </Dialog>
);
}

ScheduleDeleteDialog.propTypes = {
  open: PropTypes.instanceOf(Boolean).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  scheduleDetails: PropTypes.instanceOf(String).isRequired,
  scheduleDeleted: PropTypes.instanceOf(Function).isRequired,
};

import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { Divider, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function PasswordRecovery() {
  const [emailAddress, setEmailAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const classes = useStyles();
  async function handleChangeEmail(event) {
    setEmailAddress(event.target.value);
    if (event.target.value !== '') {
      setFormError(false);
    }
  }
  const [view, setView] = useState(0);

  async function handleSubmit(event) {
      event.preventDefault();
      const emailRegex = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
      if ((emailAddress === '') || !emailRegex.test(emailAddress)) {
        setFormError(true);
        return;
      }
      setFormError(false);
      setLoading(true);
      try {
        const data = { email: emailAddress };
        await axios.post('/data/login/recoverPassword', data);
      } catch (e) {
        setLoading(false);
        setEmailAddress('');
        setView(1);
        return;
      }
      setLoading(false);
      setEmailAddress('');
      setView(1);
  }
switch (view) {
  case 0:
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Passwort vergessen
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {!loading && (
            <TextField
              error={formError}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email-Adresse"
              name="email"
              autoComplete="email"
              helperText={formError ? 'Bitte gib eine Email-Adresse ein' : ''}
              autoFocus
              onChange={handleChangeEmail}
            />
            )}
            <div className={classes.wrapper}>
              {!loading && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Passwort wiederherstellen
              </Button>
              )}
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </form>
        </div>
      </Container>
    );
  default:
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Passwort vergessen
          </Typography>
          <Divider />
          <Typography variant="body2">
            Sofern die eingegebene Email-Adresse zu einem registriertem Konto gehört, wurde eine Email mit einem neuen Passwort verschickt.
          </Typography>
          <Divider />
          <Grid container>
            <Grid item xs>
              <Link to="/">
                Zurück
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
}
}

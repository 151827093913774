import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Editor from 'rich-markdown-editor';
import axios from 'axios';
import { Paper } from '@material-ui/core';

function WikiView(props) {
    const [mArticleData, setArticleData] = useState(props.articleData);

    const updateArticleContent = (e) => {
        const articleData = mArticleData;
        articleData.content = e();
        setArticleData(articleData);
        props.setUnstoredChanges(true);
    };

    const callSaveFunction = () => {
        const articleData = mArticleData;
        props.saveFunction(articleData);
    };

    const getBase64 = (file) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => {
                reject(error);
            };
        });

    const uploadImageCallback = async (imgBlob) => {
        const imgBase64 = await getBase64(imgBlob);
        const uploadReq = await axios.post('/data/article/image', { img: imgBase64 });
        if (uploadReq) {
            const imageId = uploadReq.data.imgId;
            return `${window.location.origin}/data/article/image/${imageId}`;
        }
        throw Error('image Upload failed');
    };
    return (
      <Paper style={{ padding: '20px' }}>
        <Editor defaultValue={mArticleData.content} readOnly={props.readOnly} onChange={updateArticleContent} onSave={callSaveFunction} uploadImage={uploadImageCallback} />
      </Paper>
    );
}

WikiView.propTypes = {
    articleData: PropTypes.shape(
                                {
                                    title: PropTypes.instanceOf(String).isRequired,
                                    content: PropTypes.instanceOf(String).isRequired,
                                    id: PropTypes.instanceOf(String),
                                },
                                ).isRequired,
    readOnly: PropTypes.instanceOf(Boolean),
    saveFunction: PropTypes.instanceOf(Function),
    setUnstoredChanges: PropTypes.instanceOf(Function),
};

WikiView.defaultProps = {
    readOnly: true,
    saveFunction: () => {},
    setUnstoredChanges: () => {},
};

export default WikiView;

import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter, RouteComponentProps } from 'react-router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
// import HomeIcon from '@material-ui/icons/Home';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
  Divider, IconButton,
  makeStyles,
  SwipeableDrawer,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
// import Typography from '@material-ui/core/Typography';
// import Badge from '@material-ui/core/Badge';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

interface Props {
  onLogout: () => void;
  isLoggedIn: boolean;
}

function Navigation({ history, onLogout, isLoggedIn }: RouteComponentProps & Props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const [mTitle, setTitle] = React.useState('');
  const [mLogoutConfirmOpen, setLogoutConfirmOpen] = React.useState(false);
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };
  const handleLogout = useCallback(() => {
    setLogoutConfirmOpen(false);
    onLogout();
    history.push('/');
  }, [onLogout]);

  const handleLoginRedirect = () => {
    setTitle('Login');
    history.push('/login');
  };

  const handleLogoutConfirmClose = () => {
    setLogoutConfirmOpen(false);
  };

  const handleLogoutConfirmOpen = () => {
    setLogoutConfirmOpen(true);
  };

  const menuIcons = [
    <HomeIcon />,
    <HistoryEduIcon />,
    <AssessmentIcon />,
    <CalendarTodayIcon />,
    <FormatListBulletedIcon />,
    <MenuBookIcon />,
  ];
  menuIcons[99] = <AccountCircleIcon />;
  const menuLinks = ['/home', '/history', '/overview', '/calendar', '/todo', '/maintenace-manual'];
  const menulistArray = (isLoggedIn ? ['Startseite', 'Geschichte', 'Sensordaten', 'Kalender', 'ToDo', 'Hausunterhalt'] : ['Startseite', 'Geschichte']);

  function ListItemLink(props: any) {
    const { index, to, text } = props;
    const renderLink = React.useMemo(
      () => React.forwardRef<HTMLAnchorElement>((itemProps, ref) => (
        <Link
          to={to}
          ref={ref}
          {...itemProps}
          onClick={toggleDrawer(false)}
        />
      )),
      [to],
    );

    useEffect(() => {
      const pageName = history.location.pathname;
      for (let i = 0; i < menuLinks.length; i += 1) {
        if (pageName === menuLinks[i]) {
          if (pageName === '/home') {
            setTitle('');
          } else {
            setTitle(menulistArray[i]);
          }
        }
      }
    }, [history]);

    return (
      <li>
        <ListItem button component={renderLink}>
          <ListItemIcon>{menuIcons[index]}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </li>
    );
  }
  const menulist = (
    <List>
      <div>
        {menulistArray.map((text, index) => (
          <ListItemLink to={menuLinks[index]} index={index} text={text} key={index} />
        ))}
        { isLoggedIn
        ? (
          <>
            <Divider />
            <ListItemLink to="/account" index={99} text="Account" key={99} />
          </>
          )
        : null }
      </div>
    </List>
  );

  return (
    <AppBar>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          id="navigation-menu"
          keepMounted
          open={state}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
          classes={{
            paper: clsx(classes.drawerPaper, !state && classes.drawerPaperClose),
          }}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={toggleDrawer(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {menulist}
        </SwipeableDrawer>
        <div className={classes.title}>
          {mTitle}
        </div>
        { history.location.pathname === '/login'
        ? null
        : (
          <>
            { isLoggedIn
            ? (
              <>
                <IconButton
                  edge="end"
                  color="secondary"
                  aria-label="Logout"
                  onClick={handleLogoutConfirmOpen}
                >
                  <LogoutIcon />
                </IconButton>
                <Dialog
                  open={mLogoutConfirmOpen}
                  onClose={handleLogoutConfirmClose}
                >
                  <DialogTitle>Abmelden</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Möchten Sie sich wirklich abmelden?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleLogoutConfirmClose} color="primary">
                      Nein
                    </Button>
                    <Button onClick={handleLogout} color="secondary">
                      Ja
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
              )
            : (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Login"
                onClick={handleLoginRedirect}
              >
                <LoginIcon />
              </IconButton>
              ) }
          </>
          ) }
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(Navigation);

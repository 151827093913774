import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Grid,
  Paper,
  InputLabel,
  FormControl,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import DataVisualisation from './DataVisualisation';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
});

export default function SensorStatusCard({ sensorInfo, title, details }) {
  const [open, setOpen] = useState(false);
  const [mDays, setDays] = useState(7);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTimeSpanChange = (e) => {
    setDays(e.target.value);
  };

  const classes = useStyles();
  const cardTitle = title ? <Typography className={classes.title} color="textPrimary" gutterBottom>{title}</Typography> : <Typography className={classes.title} style={{ height: '25.9px' }} />;
  const detailTitle = details ? `"${details.title}_detail"` : `"${sensorInfo.title}_detail"`;
  return (
    <Card className={classes.root}>
      <CardContent height="320px">
        {cardTitle}
        <div style={{
          position: 'relative',
          height: '180px',
          minWidth: '275px',
          }}
        >
          <DataVisualisation dataSources={sensorInfo.data} chartOptions={sensorInfo.options} days={1} />
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen}>Details</Button>
      </CardActions>
      <Modal open={open} onClose={handleClose}>
        <Paper
          width="80%"
          style={{
            marginTop: '20vh', marginLeft: 'auto', marginRight: 'auto', position: 'sticky', width: '80%', height: '50vh', maxWidth: '1440px',
          }}
        >
          <Grid container spacing={3} style={{ height: '100%' }}>
            <Grid item xs={11}>
              <FormControl style={{ marginLeft: '10px' }}>
                <InputLabel id="timerange-label">Zeitraum</InputLabel>
                <Select
                  native
                  value={(mDays)}
                  onChange={handleTimeSpanChange}
                  fullWidth
                  inputProps={{
                    name: 'Zeitraum',
                    id: 'timeRange',
                  }}
                >
                  <option value={1}>1 Tag</option>
                  <option value={3}>3 Tage</option>
                  <option value={7}>7 Tage</option>
                  <option value={30}>30 Tage</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Logout"
                onClick={handleClose}
                style={{ float: 'right', marginRight: '10px' }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} style={{ position: 'relative', height: '80%' }}>
              <DataVisualisation title={detailTitle} dataSources={details.data} chartOptions={details.options} days={mDays} />
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </Card>
  );
}

SensorStatusCard.propTypes = {
  sensorInfo: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.instanceOf(String),
  details: PropTypes.instanceOf(Object),
};

SensorStatusCard.defaultProps = {
  title: undefined,
  details: undefined,
};

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';

function MaintenanceNavigator(props) {
  const createButtonIcn = (
    <IconButton
      edge="end"
      aria-label="edit"
      onClick={props.createButton}
      disabled={props.createButton === null}
    >
      <AddIcon />
    </IconButton>
  );
  const backButtonIcn = (
    <IconButton
      edge="end"
      aria-label="edit"
      onClick={props.backButton}
      disabled={props.backButton === null}
    >
      <ArrowBackIcon />
    </IconButton>
  );
  const saveButtonIcn = (
    <IconButton
      edge="end"
      aria-label="edit"
      onClick={props.saveButton}
      disabled={props.saveButton === null}
    >
      <SaveIcon />
    </IconButton>
  );
  const editButtonIcn = (
    <IconButton
      edge="end"
      aria-label="edit"
      onClick={props.editButton}
      disabled={props.editButton === null}
    >
      <EditIcon />
    </IconButton>
  );
  const formattedTitle = `${props.title}${props.isNotStored ? '*' : ''}`;
  return (
    <Paper style={{ padding: '20px', marginBottom: '10px', display: 'flex' }}>
      <Typography variant="h5">{formattedTitle}</Typography>
      <div style={{ flexGrow: '100' }} />
      {props.createButton ? createButtonIcn : null}
      {props.saveButton ? saveButtonIcn : null}
      {props.editButton ? editButtonIcn : null}
      {props.backButton ? backButtonIcn : null}
    </Paper>
  );
}

MaintenanceNavigator.propTypes = {
  createButton: PropTypes.instanceOf(Function),
  backButton: PropTypes.instanceOf(Function),
  saveButton: PropTypes.instanceOf(Function),
  editButton: PropTypes.instanceOf(Function),
  title: PropTypes.instanceOf(String),
  isNotStored: PropTypes.instanceOf(Boolean),
};

MaintenanceNavigator.defaultProps = {
  createButton: null,
  backButton: null,
  saveButton: null,
  editButton: null,
  title: '',
  isNotStored: false,
};

export default MaintenanceNavigator;

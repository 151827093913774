import React from 'react';
import { Container } from '@material-ui/core';
import notFound from './404pepo.png';

export default function NotFound() {
  return (
    <Container>
      <img src={notFound} alt="404 Not Found" />
    </Container>
  );
}
